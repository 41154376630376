/**
 * @component behaviors/stickyToBottomContainer
 * @description Pin sticky bar
 */

/**
 * Move the sticky bar to the bottom of the screen
 */
function moveStickyBar() {
    const stickyBottom = document.querySelector('.js-stickyToBottom');
    if (!stickyBottom) return;

    const main = stickyBottom.closest('main');
    if (main) {
        stickyBottom.remove();
        main.append(stickyBottom);
    }
}

/**
 * Initialize component, register all event handlers
 */
function initializeComponent() {
    moveStickyBar();
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', initializeComponent)
    : initializeComponent();
